import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { Button, Form, Modal } from "react-bootstrap";

import { FaLocationDot } from "react-icons/fa6";

import { FloatButton } from "antd";
const Author = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);
Author.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};
function Tables() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Fetch user data from your API here
    fetch("https://backend.svips.se/post/get-paid-posts")
      .then((response) => response.json())
      .then((data) => {
        if (data?.data) {
          setUserData(data.data);

          // Assuming the data is an array of user objects
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
  const handleClose = () => {
    setShow(false);
    setValue();
  };
  const handleOpen = (data) => {
    setValue(data);
    setShow(true);
  };
  return (
    <div
      className="scroll-container"
      style={{
        maxHeight: "100vh", // Make sure the container has a height limit
        overflowY: "auto", // Enable vertical scrolling
        zIndex: open ? -1 : "auto", // Conditionally apply zIndex
      }}
    >
      <Modal
        style={{
          marginTop: "60px",
          zIndex: 1050,
          position: "fixed", // Make sure the modal is fixed in the viewport
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "25px" }}>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <>
              <h5>Service Name: {value?.serviceName}</h5>
              <div style={{ width: "100%" }} className="mt-3">
                <h5>Post Owner Detail:</h5>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "80px",
                      borderRadius: "50px",
                      height: "80px",
                      objectFit: "cover",
                    }}
                    src={value?.ownerId?.profilePic}
                    alt="Profile"
                  />
                  <div>
                    <h6>{value?.ownerId?.fullname}</h6>
                    <h6>{value?.ownerId?.email}</h6>
                  </div>
                </div>
                <h5 className="mt-3">Driver Detail:</h5>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "80px",
                      borderRadius: "50px",
                      height: "80px",
                      objectFit: "cover",
                    }}
                    src={value?.driverId?.profilePic}
                    alt="Profile"
                  />
                  <div>
                    <h6>{value?.driverId?.fullname}</h6>
                    <h6>{value?.driverId?.email}</h6>
                  </div>
                </div>
                <div className="mt-3">
                  <h5>Driver Comment:</h5>
                  <h6>
                    {value?.driverComment
                      ? value?.driverComment === "undefined"
                        ? "----"
                        : `• ${value?.driverComment}`
                      : ""}
                  </h6>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "50px",
                  }}
                >
                  <div>
                    <h5 style={{ marginTop: 20 }}>Pickup Location :</h5>
                    <h6 className="mt-1">
                      <FaLocationDot />
                      <span className="ms-2">
                        {value?.shoppingLocation?.title || value?.pickupLocation?.title}
                      </span>
                    </h6>
                  </div>
                  <div>
                    <h5 style={{ marginTop: 20 }}>Drop Location :</h5>
                    <h6 className="mt-1">
                      <FaLocationDot />
                      <span className="ms-2">{value?.dropLocation?.title || "----"}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </>
          </Form>
        </Modal.Body>
      </Modal>

      <>
        {userData.length === 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Set the height of the container to 100% of the viewport height
            }}
          >
            No posts found
          </div>
        ) : (
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Transaction List
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={3}>
                      {loading ? (
                        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                          <Skeleton animation={true} />
                          <Skeleton animation={true} />
                          <Skeleton animation={true} />
                          <Skeleton animation={true} />
                        </div>
                      ) : (
                        <DataTable
                          table={{
                            columns: [
                              { Header: "Date", accessor: "date", align: "left" },
                              { Header: "Driver Name", accessor: "name", align: "left" },
                              // { Header: "Service Name", accessor: "serviceName", align: "left" },
                              // { Header: "Status", accessor: "status", align: "left" },
                              { Header: "Price", accessor: "priceOffer", align: "left" },
                              // {
                              //   Header: "WithDraw Amount",
                              //   accessor: "amount",
                              //   align: "left",
                              // },
                              // { Header: "Comment", accessor: "driverComment", align: "left" },
                              {
                                Header: "Payment Status",
                                accessor: "paymentStatus",
                                align: "left",
                              },
                              { Header: "Order Status", accessor: "orderStatus", align: "left" },
                              {
                                Header: "Details",
                                accessor: "details",
                                align: "center",
                                Cell: (row) => {
                                  return (
                                    <button
                                      onClick={() =>
                                        handleOpen(
                                          userData?.find(
                                            (data) => data?._id === row?.row?.original?.postId
                                          )
                                        )
                                      }
                                      className="btn btn-info"
                                      style={{
                                        background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                                        color: "white",
                                      }}
                                      // disabled={row.row.original.blocked === true}
                                    >
                                      Details
                                    </button>
                                  );
                                },
                              },
                            ],
                            rows:
                              userData?.length > 0 &&
                              userData?.map((user) => ({
                                date: formatDate(user?.createdAt),

                                name: (
                                  <Author
                                    image={user?.driverId?.profilePic}
                                    name={user?.driverId?.fullname}
                                    email={user?.driverId?.email}
                                  />
                                ),
                                // serviceName: user?.serviceName,
                                // status: user?.status,
                                postId: user?._id,
                                priceOffer: user?.currency + " " + user?.priceOffer,
                                // driverComment: user?.driverComment,
                                paymentStatus: user?.paymentStatus,
                                orderStatus:
                                  user?.orderStatus === "active" ? "completed" : user?.orderStatus,
                                // amount: user?.ownerId?.userCommission,
                              })),
                          }}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      )}
                    </MDBox>
                    <FloatButton.BackTop style={{ marginBottom: "55px", marginRight: "20px" }} />
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            {/* <Footer /> */}
          </DashboardLayout>
        )}
      </>
    </div>
  );
}

export default Tables;
